<template>
  <NuxtLink
    :to="parsedLink.to"
    :target="parsedLink.target"
    :rel="parsedLink.rel"
    :class="[parsedLink.class, activeClassName]"
    :title="parsedLink.title"
    class="t3-link"
    data-allow-mismatch="class"
  >
    <slot />
  </NuxtLink>
</template>

<script setup lang="ts">
import type { T3Link } from '@t3headless/nuxt-typo3'
import { withoutTrailingSlash } from 'ufo'
import { computed } from 'vue'

import { useRoute, useT3Options } from '#imports'

import type { T3LinkProps } from '~shared/types'

import { getValidLink, isCompanyPage, isInternalPage, isTargetAttributeValid } from '~ui/helpers/linkUtils'

const props = defineProps<T3LinkProps>()

const { options } = useT3Options()

const parsedLink = computed(() => {
  const link = props.to ?? props.link

  const rawHref = getValidLink(link)
  if (!rawHref) return { to: '/', target: null, rel: null, class: null, title: null }

  const isInternal = isInternalPage(rawHref, options.baseUrl!)

  const href = isInternal ? rawHref.replace(options.baseUrl!, '') : rawHref

  const blankTargetAttrs = isInternal || isCompanyPage(href)
    ? { target: '_blank', rel: 'noopener' }
    : { target: '_blank', rel: 'noopener noreferrer nofollow' }

  let attrs = {
    target: (link as T3Link)?.target || null,
    class: (link as T3Link)?.class || null,
    title: (link as T3Link)?.title || null
  }

  // eslint-disable-next-line @stylistic/no-extra-parens
  if (attrs.target === '_blank' || (!attrs.target && !isInternal) || !isTargetAttributeValid(attrs.target)) {
    attrs = { ...attrs, ...blankTargetAttrs }
  }

  return { to: withoutTrailingSlash(href, true), ...attrs }
})

const route = useRoute()

const activeClassName = computed(() => {
  if (!props.activeClass) return null

  let className = 'router-link-active'

  if (typeof props.activeClass === 'string') {
    className = props.activeClass
  }

  const [path, href] = [route.path, parsedLink.value.to]
  return path.startsWith(href) ? className : null
})
</script>
